<template>
  <view-item title="收款单详情">
    <template #manifest>
      <table-manifest
        name="收款单"
        code="RCTSEFD"
        :ajax="{ action: 'GET /enocloud/settlement/receipt/query' }"
        :props="{ end: 'businessEndDate', start: 'businessStartDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
      >
        <template #form="{ data }">
          <en-form-item label="业务单号">
            <en-input v-model="data.receiptQuickSearch" placeholder="收款对象/业务单号/收款单号"></en-input>
          </en-form-item>
          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => {
                  params.payload = { name: value }
                }
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="收款方式" prop="paymentMethod">
            <select-maintain
              v-model="data.paymentMethod"
              :ajax="{
                action: 'GET /enocloud/common/hint/:hintTypeStr',
                params: (params) => (params.paths = ['PAMTMTD'])
              }"
              :props="{ label: 'name', value: 'name' }"
              value-key="code"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data.serialNo }}</span>
                <span>{{ form.data.payer?.name }}</span>
                <span>{{ form.data.branch?.shortName }}</span>
                <span>{{ form.data.preparedBy?.name }}</span>
                <span>{{ formatMoney(form.data.amount) }}（收款金额）</span>
                <span> {{ formatDate(form.data.businessDatetime) }}（单据）</span>
                <span> {{ formatDate(form.data.preparedDatetime) }}（收款）</span>
              </div>
            </template>
            <div class="grid-cols-4 gap-x-6">
              <span>备注：{{ form.data.comment }}</span>
            </div>
          </en-collapse-item>
        </en-collapse>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <en-table :data="form.data.receiptReceivables">
            <en-table-column label="单据类型" prop="receivable.type.message"></en-table-column>
            <en-table-column label="车牌号" prop="receivable.service.vehicle.plateNo"></en-table-column>
            <en-table-column label="业务单号" prop="receivable.serialNo"> </en-table-column>
            <en-table-column label="应收款">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['ReceiptDto'] }">
                {{ formatMoney(row.receivable?.amount) }}
              </template></en-table-column
            >
            <en-table-column label="已收款">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['ReceiptDto'] }">
                {{ formatMoney(row.receivable?.receivedAmount) }}
              </template>
            </en-table-column>
            <en-table-column label="收款">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['ReceiptDto'] }">
                {{ formatMoney(row.amount) }}
              </template>
            </en-table-column>
            <en-table-column label="优惠减免">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['ReceiptDto'] }">
                {{ formatMoney(row.badDebt) }}
              </template>
            </en-table-column>
            <en-table-column label="冲销金额">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['ReceiptDto'] }">
                {{ formatMoney(row.receivable.receiptAdvanceDeposit) }}
              </template>
            </en-table-column>
            <en-table-column label="应收类型" prop="receivable.type.message"></en-table-column>
            <en-table-column label="结算日期">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['ReceiptDto'] }">
                {{ formatDate(row.receivable?.settlementDatetime) }}
              </template>
            </en-table-column>
            <en-table-column label="收款方式" prop="paymentMethod"></en-table-column>
          </en-table>
          <en-form-item label="凭证图片：" class="col-start-1">
            <upload-maintain
              v-if="form.data.receiptVoucherImgUrls.length > 0"
              v-model="form.data.receiptVoucherImgUrls"
              :limit="1"
              disabled
              multiple
            ></upload-maintain>
            <span v-else>无</span>
          </en-form-item>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>
  <payorder-dialog v-model="dialog.visible"></payorder-dialog>
</template>

<script lang="ts">
import PayorderDialog from '@settlement/components/payorder-dialog.vue'
export default factory({
  components: { PayorderDialog },

  config: {
    children: {
      operation: {
        add: {
          click() {
            this.form.init()

            this.detail.visible = true
          }
        },

        option: {
          async command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
            }
          }
        }
      },
      manifest: {
        children: {
          form: {},
          row: {
            click(row: EnocloudServiceDefinitions['ServiceReservationQueryDto']) {
              this.form.init()
              this.form.data.id = row.id
              this.form.get()
            }
          }
        }
      },
      tabs: {
        active: 'maintenance'
      },
      form: {
        data: {
          preparedDatetime: '',
          businessDatetime: '',
          serialNo: '',
          preparedBy: {
            name: ''
          },
          amount: 0,
          payer: { name: '' },
          comment: '',
          receiptReceivables: [],
          receiptVoucherImgUrls: []
        },
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/receipt/:receiptId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            },

            convert(data) {
              for (let item of data.receiptReceivables) {
                item.badDept = 0
                item.paymentMethod = data.paymentMethod
                if (item.receivable.type.code === 'SRV') item.badDebt = Math.abs(item.receivable.service.receivableBadDebt)
                if (item.receivable.type.code === 'SAL') item.badDebt = Math.abs(item.receivable.sale.receivableBadDebt)
              }
              return data
            }
          }
        },
        children: {
          maintenances: {
            selection: {
              data: [] as EnocloudSettlementDefinitions['ReceiptDto'][],
              change(rows: EnocloudSettlementDefinitions['ReceiptDto'][]) {
                this.form.maintenances.selection.data = rows
              }
            },
            operation: {}
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      }
    }
  },
  mounted() {
    this.form.get()
  }
})
</script>
